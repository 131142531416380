import register from "preact-custom-element";
import UmtGlobalNav from "./UmtGlobalNav";

if (!customElements.get("umt-global-nav")) {
  register(UmtGlobalNav, "umt-global-nav", ["search-query", "hide-links"], {
    shadow: true,
  });
}

export default UmtGlobalNav;
